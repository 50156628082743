@import '../../assets/variables.scss';
.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;

  .container {
    max-width: 848px;
    width: 100%;
    margin: 36px;
    padding: 20px;
    background-color: white;
  }
  h3 {
    font-weight: bold;
    padding-bottom: 20px;
    color: #000035;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 24px;
    line-height: 36px;
  }
  .table {
    padding-top: 0px;

    .actions {
      display: flex;
      justify-content: center;
      div {
        padding: 0 10px;
      }
    }
  }
  .tableBody {
    background-color: #f4f4f4 !important;
    height: 500px !important;
  }
}
