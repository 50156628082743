$main: #000035;
$neon: #33ffcc;
$darkerneon: #2cebbb;
$deepdarkerneon: #0acb9b;
$orange: #ff9b3d;
$darkerorange: #da771a;
$deepBlack: #161616;
$grayBackground: #e5e5e5;
$white: #ffffff;
$blue: #003fc2;
