@import '../../../assets/variables.scss';
.root {
  padding: 40px 20px 20px;
  background-color: $white;
  position: relative;
  width: 770px;
  .title {
    font-weight: bold;
    padding-bottom: 20px;
    color: $main;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 24px;
    line-height: 36px;
    margin-top: -11px;
  }
  .fileName {
    padding: 10px;
    font-size: 25px;
    color: black;
    background-color: white;
  }
  .subtitle {
    margin-top: 20px;
    font-size: 15px;
    color: black;
  }
  .btnlink {
    display: flex;
    flex-direction: row;
  }
  .btnFilePreviewCSV {
    margin-top: 48px;
    width: 92px;
  }
  .total {
    padding-top: 0px;
    font-size: 13px;
    color: #000035;
    font-family: 'Public Sans';
  }
  .previewText {
    margin-top: -12px;
    margin-bottom: 5px;
    font-size: 15px;
    color: #000035;
    font-family: 'Public Sans';
  }
  .table {
    padding-top: 15px;
  }
  .tableBody {
    background-color: #f4f4f4 !important;
    height: 500px !important;
  }
  .loading {
    padding-top: 100px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .error {
    display: flex;
    justify-content: center;
    padding-top: 50px;
  }
  .link2 {
    text-decoration: none;
    color: $main;
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    padding-top: 77px;
    margin-left: 464px;
    cursor: pointer;
  }  
  .link2:hover{
    color:$main;
  }
  .link2:active{
    color:$main;
  }
  .icon{
    margin-left: 10px;
  }
  .underlineContainer{
    padding: 0px 16px -10px;
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-left: 10px;
  }
}
