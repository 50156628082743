@import '../../assets/variables.scss';
.root {
  width: 100%;
  padding: 40px 20px 20px;
  background-color: #f4f4f4;
  position: relative;

  h3 {
    font-size: 22px;
    font-weight: bold;
    padding-bottom: 20px;
    color: $main;
    font-family: 'Poppins';
    font-style: normal;
    line-height: 36px;
  }
  h6{
    color: $main;
  }
  p {
    padding: 8px 0 20px 0;
    color: $main;
  }
  .tableContainer {
    padding-bottom: 30px;
    .table {
      .cell {
        border-top: none;
        border-bottom: none;
        .expandedContent {
          display: flex;
          justify-content: space-between;
          padding-top: 10px;
          padding-bottom: 10px;

          @media (max-width: 768px) {
            flex-direction: column;
          }

          .term {
            min-width: 233px;
          }
          .description {
            max-width: 300px;
            width: 356px;
            margin-left: -8px;
          }
        }
      }
    }
  }
  .btn {
    padding-top: 15px;
    width: 110px;
    height: 46px;
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
  }
  .text {
    padding: 0;
    color: #393939;
    font-size: 10px;
    position: absolute;
    left: 20px;
    bottom: 12px;

    @media (max-width: 767px) {
      bottom: -30px;
    }

    a {
      color: #0353e9;
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .btnWrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .searchDisclaimer p {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: $main;
  }
}
