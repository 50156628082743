@import '../../assets/variables.scss';
.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  background-color: $grayBackground;
  padding: 0;
  flex-direction: column;

  .classifier {
    max-width: 760px;
  }
}
