@import '../../assets/variables.scss';

.root {
  height: 100%;
  width: 264px;
  background-color: $main;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  .logoContainer {
    display: flex;
    justify-content: center;
    padding-top: 48px;
    padding-bottom: 71px;

    & > img {
      width: 112px;
    }
  }

  .content {
    color: white;
    padding: 0 32px;
    padding-bottom: 32px;
    flex: 1;
    display: flex;
    flex-direction: column;
    .section {
      font-size: 24px;
      font-weight: 500;
      padding-bottom: 8px;
    }

    .navigationItemContainer {
      height: 32px;
      display: flex;
      align-items: center;
      .bulletContainer {
        display: flex;
        align-items: center;
        width: 13px;
        .bullet {
          width: 5px;
          height: 5px;
          background-color: $neon;
          border-radius: 50px;
        }
      }
      a {
        cursor: pointer;
        color: white;
        text-decoration: none;
        font-size: 16px;
      }

      .active {
        color: $neon;
        font-weight: 600;
      }
    }

    .fill {
      flex: 1;
    }
  }
}
