@import '../../assets/variables.scss';

.tagContainer {
  height: 24px;
  width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  p {
    font-size: 12px;
    font-family: 'IBM Plex Sans';
    font-weight: 700;
    line-height: 16px;
    word-spacing: 0.32px;
  }
}

.courier {
  background-color: $orange;
}

.free {
  background-color: #ffffff;
  color: $deepBlack;
}
