@import './assets/variables.scss';
.app {
  display: flex;
  padding: 0;
  height: 100vh;
  align-items: center;
  flex-direction: row;
  background-color: #e5e5e5;

  .content {
    display: flex;
    // justify-content: center;
    align-items: center;
    flex: 1;
    background-color: $grayBackground;
    padding: 0;
    flex-direction: column;
    height: 100vh;
    overflow-y: auto;
  }
}

.centerContainer {
  flex: 1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header {
  background-color: #0f62fe;
  margin-bottom: 80px;
  min-height: 60px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  @media (min-width: 768px) {
    min-height: 110px;
    padding-left: 40px;
    padding-right: 40px;
  }

  h1 {
    color: #fff;
    margin: 0;
    font-size: 30px;
    font-weight: bold;
  }
}
.headerLinks {
  flex-direction: row;
  justify-content: space-between;

  a {
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 14px;
    cursor: pointer;
    transition: 0.2s ease all;
    border-bottom: 1px solid transparent;

    &:hover {
      color: #fff;
      text-decoration: none;
      border-bottom: 1px solid #fff;
    }
  }
}

.loadingContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verificationContainer {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p:nth-child(2) {
    margin-bottom: 20px;
  }
}

.text {
  padding-left: 20px;
  padding-right: 20px;
  h6 {
    font-weight: bold;
    text-align: center;
  }
  .underlineP {
    text-decoration: underline;
    margin: 0;
    display: inline;
  }
}

// .useDesktop {
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   display: none;
// }
//
// @media only screen and (max-width: 1000px) {
//   .app {
//     display: none;
//   }

//   .useDesktop {
//     display: flex;
//   }
// }
