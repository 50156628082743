@import '../../assets/variables.scss';
.descriptionContainer {
  padding-left: 18px;
  width: 110%;
}
.picture {
  width: 349px;
  height: 210.72px;
}
.description {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.16px;
  margin-left: -10px;
}
.container {
  display: flex;
  margin-top: 8px;
}
.stepsContainer {
  display: flex;
  flex-direction: column;
  padding: 13px;
  padding-top: 4px;
}
.stepBox {
  width: 171px;
  height: 100%;
  background: $main;
  color: #ffffff;
  font-size: 11px;
  line-height: 13px;
  padding: 9px;
  margin-top: 5px;
}
.downloadButton{
  width: 204px;
  margin-left: 4px;
  margin-top: 10px;
}