.text {
  padding-left: 20px;
  padding-right: 20px;
  h6 {
    font-weight: bold;
    text-align: center;
  }
  .underlineP {
    text-decoration: underline;
    margin: 0;
    display: inline;
  }
}

.modal {
  & > div {
    width: 780px;
  }
}
