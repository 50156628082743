.container {
  height: 5px;
  width: 100%;
  background-color: #c4c4c4;
  border-radius: 50px;
  .fill {
    height: 100%;
    transition: width 1s ease-in-out;
    border-radius: 50px;
  }
}
