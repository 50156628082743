@import '../../assets/variables.scss';

.root {
  display: flex;
  flex: 1;
  background-color: $grayBackground;
  height: 100%;
  justify-content: center;
  align-items: center;

  .container {
    width: 1088px;
    background-color: white;
    display: flex;
    .illustrationContainer {
      width: 352px;
      min-height: 633px;
      background-color: $main;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .logoContainer {
        display: flex;
        justify-content: center;
        padding-top: 67px;
        padding-bottom: 71px;

        & > img {
          width: 110px;
        }
      }

      .illustration {
        padding-bottom: 21px;
      }
    }

    .formContainer {
      padding: 24px 32px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .form {
        .header {
          font-size: 24px;
          font-weight: 500;
          margin-bottom: 8px;
          line-height: 36px;
        }

        .subheader {
          max-width: 400px;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 24px;
        }

        .row {
          flex: 1;
          display: flex;
          justify-content: space-between;
          margin-bottom: 17px;
          .shortInput {
            width: 320px;
          }
        }

        .requiredDisclaimer {
          font-size: 12px;
          line-height: 16px;
        }
      }

      .formActions {
        height: 48px;
        display: flex;
        justify-content: flex-end;

        .submit {
          font-size: 16px;
          padding-left: 15.18px;
          padding-right: 15.18px;
        }

        .linkContainer {
          padding: 0px 16px;
          display: flex;
          align-items: center;
          .link {
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;
            color: $main;
            text-decoration: none;
            display: flex;
            flex-direction: column;
            .underline {
              width: 40px;
              height: 1px;
              background-color: $main;
              margin-top: 4px;
            }
          }
        }
      }
    }
  }
}
