.container {
  margin: 10px;
  background-color: white;

  .header {
    background-color: white;
    padding: 10px 16px;
    p {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.sub {
  padding: 6px 10px;
}

.total {
  margin-bottom: 40px;
  padding: 10px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.actions {
  padding: 10px;
}

.btn {
  border-style: solid;
  bottom: 6px;
  width: 170px;
  height: 48px;
  font-size: 16px;
  margin-left: 4px;
  font-weight: bold;
}

.filePreviewModal > div {
  width: auto;
  max-width: 1200px;
  height: auto;
  max-height: 1000px;
  background-color: #f2f2f2;
}
