@import '../../../assets/variables.scss';
.root {
  padding: 24px;
  background-color: $white;
  position: relative;
  width: 760px;
  display: flex;
  flex-direction: column;
  .title {
    font-weight: bold;
    padding-bottom: 20px;
    color: $main;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 24px;
    line-height: 36px;
  }
  .container {
    flex: 1;
  }
  .link {
    font-size: 15px;
    cursor: pointer;
    color: #0033c0;
    padding-right: 30px;
    padding: 1px;
    text-decoration: none;
    padding-top: 43px;
  }
  .btnlink {
    display: flex;
    flex-direction: row;
  }
  .image {
    width: 89px;
    height: 60px;
  }

  .imageLink {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 480px;
  }
}
