.root {
  padding: 30px;
  padding-top: 115px;
  height: 100vh;
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  .container {
    background-color: #fff5ec;
    max-width: 792px;
    height: 195px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 44px;

    p {
      font-size: 24px;
      line-height: 36px;
      font-weight: 500;
    }
  }
}
