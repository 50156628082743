@import '../../assets/variables.scss';
.link {
    font-size: 16px;
    line-height: 24px;
    color: $main !important;
    padding: 1px;
    text-decoration: none !important;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .underline {
      width: 100%;
      height: 2px;
      background-color: $main;
      margin-top: 2px;
      cursor: pointer;
    }
  }
  .link:hover .underline {
    background-color: $neon;
    cursor: pointer;
  }
  .link:active .underline {
    background-color: $deepdarkerneon;
    cursor: pointer;
  }
  a {
    text-decoration: none !important;
    color: $main;
    cursor: pointer;
  }

